body {
    box-sizing: border-box;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*, body,
html {
    font-family: "Manrope", cursive;
    box-sizing: border-box;
}
p {
    margin: 0;
}
@font-face {
    font-family: "Manrope";
    font-weight: 800;
    src: local("Manrope"), url(./fonts/Manrope-ExtraBold.ttf) format("truetype");
}
@font-face {
    font-family: "Manrope";
    font-weight: 700;
    src: local("Manrope"), url(./fonts/Manrope-Bold.ttf) format("truetype");
}
@font-face {
    font-family: "Manrope";
    font-weight: 600;
    src: local("Manrope"), url(./fonts/Manrope-SemiBold.ttf) format("truetype");
}
@font-face {
    font-family: "Manrope";
    font-weight: 500;
    src: local("Manrope"), url(./fonts/Manrope-Medium.ttf) format("truetype");
}
@font-face {
    font-family: "Manrope";
    font-weight: 400;
    src: local("Manrope"), url(./fonts/Manrope-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "Manrope";
    src: local("Manrope"), url(./fonts/Manrope-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Manrope";
    font-weight: 300;
    src: local("Manrope"), url(./fonts/Manrope-Light.ttf) format("truetype");
}
@font-face {
    font-family: "Manrope";
    font-weight: 200;
    src: local("Manrope"), url(./fonts/Manrope-ExtraLight.ttf) format("truetype");
}
